import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Library, Playbook, UserPlaybookStatus } from "../../shared/types";
import { getAllPlaybooks, updateUser, useUser } from "../services/firebase";

// GPS Components
import Layout from "../components/Layout";

// External Components
import { ReactSortable, ItemInterface } from "react-sortablejs";
import { createListFromOrderedItems } from "../services/gpsUtil";

function EditUserPage() {
    const { id } = useParams();
    const currentUser = useUser(id!);

    const [playbookLib, setPlaybookLib] = useState<Library<Playbook>>({});
    const [playbookList, setPlaybookList] = useState<ItemInterface[]>([]);

    const [selectedPlaybookId, setSelectedPlaybookId] = useState<string>('');

    useEffect(() => {
        getInitialProps();

        async function getInitialProps() {
            if (currentUser?.playbookStatus) {
                const newPlaybookList = createListFromOrderedItems(currentUser.playbookStatus);
                setPlaybookList(newPlaybookList);
            }

            const playbooksRes = await getAllPlaybooks();
            if (playbooksRes.ok) {

                const newPlaybookLib: Library<Playbook> = {};

                playbooksRes.val.forEach((playbook) => {
                    newPlaybookLib[playbook.id] = playbook;
                });

                setPlaybookLib(newPlaybookLib);
            }
        }
    }, [currentUser]);

    function addPlaybook(playbookId: string) {
        const newPlaybookList = JSON.parse(JSON.stringify(playbookList));
        newPlaybookList.push({ id: playbookId });
        setPlaybookList(newPlaybookList);

        setSelectedPlaybookId('');
    }

    function removePlaybook(itemPosition: number) {
        const newPlaybookList = JSON.parse(JSON.stringify(playbookList));
        newPlaybookList.splice(itemPosition, 1);
        setPlaybookList(newPlaybookList);
    }

    async function saveUser() {
        if (currentUser?.playbookStatus) {
            const newPlaybookStatus: Library<UserPlaybookStatus> = currentUser.playbookStatus;

            for (const [i, playbook] of playbookList.entries()) {
                newPlaybookStatus[playbook.id] = currentUser.playbookStatus[playbook.id] || {};
                newPlaybookStatus[playbook.id].order = i;
            }

            for (const playbookId of Object.keys(newPlaybookStatus)) {
                if (typeof playbookList.find((item) => item.id === playbookId) === 'undefined') {
                    delete newPlaybookStatus[playbookId];
                }
            }

            await updateUser({ ...currentUser, playbookStatus: newPlaybookStatus });
        }
    }

    return (
        <Layout>
            <div className="flex align-items-center justify-content-between mb-100">
                {currentUser?.email}
                <button className="btn btn-primary" onClick={saveUser}>Save User</button>
            </div>

            <ReactSortable list={playbookList} setList={setPlaybookList}>
                {playbookList?.map((item, i) =>
                    <div className="card mb-100" key={item.id}>
                        {playbookLib[item.id]?.name}
                        {item.id} {item.playbookId}
                        <button onClick={() => removePlaybook(i)}>remove...</button>
                    </div>
                )}
            </ReactSortable>

            {playbookLib ? (
                <>
                    <select id="playbook" value={selectedPlaybookId} onChange={(e) => setSelectedPlaybookId(e.target.value)}>
                        <option value="" disabled>Choose a Playbook</option>
                        {Object.values(playbookLib).map((playbook) =>
                            <option key={playbook.id} value={playbook.id} disabled={Boolean(playbookList.find((item) => item.id === playbook.id))}>{playbook.name}</option>
                        )}
                    </select>

                    <button onClick={() => addPlaybook(selectedPlaybookId)} disabled={!selectedPlaybookId}>+ Add Playbook</button>
                </>
            ) : null}

        </Layout>
    );
}

export default EditUserPage;