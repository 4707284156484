import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { getPlaybook, updatePlaybook } from "../services/firebase";
import { Playbook, Task, Library } from "../../shared/types";
import { slugifyWithCounter } from '@sindresorhus/slugify';
import { createListFromOrderedItems } from "../services/gpsUtil";

// Components
import { ReactSortable, ItemInterface } from "react-sortablejs";
import Layout from "../components/Layout";
import Tiptap from "../components/Tiptap";
import { Alert } from "@mui/material";

function EditPlaybookPage() {
    const params = useParams();

    const [playbook, setPlaybook] = useState<Playbook>({ id: '', tasks: {} });
    const [loading, setLoading] = useState(false);
    const [showAlert, setShowAlert] = useState(<></>);
    const [taskList, setTaskList] = useState<ItemInterface[]>([]); // NEW

    useEffect(() => {
        getInitialProps();

        async function getInitialProps() {
            if (params.id) {
                const res = await getPlaybook(params.id);

                if (res.ok) {
                    setPlaybook(res.val);

                    if (res.val.tasks) {
                        const newTaskList = createListFromOrderedItems(res.val.tasks);
                        setTaskList(newTaskList);
                    }
                }


            }
        }
    }, [params.id]);

    // If the Playbook's tasks change, rerender the list...
    useEffect(() => {
        const newTaskList = createListFromOrderedItems(playbook.tasks);
        setTaskList(newTaskList);
    }, [playbook.tasks]);

    function updateTasks(taskId: string | number, field: string, value: unknown) {
        const newPlaybook = JSON.parse(JSON.stringify(playbook));
        newPlaybook.tasks[taskId][field] = value;
        setPlaybook(newPlaybook);
    }

    // Handle Re-Order
    function handleReorder(newList: ItemInterface[]) {
        const newPlaybook: Playbook = JSON.parse(JSON.stringify(playbook));
        for (const [i, item] of newList.entries()) {
            newPlaybook.tasks[item.id].order = i;
        }
        setPlaybook(newPlaybook);
        // Task List updated via Effect...
    }

    function addTask() {
        const newTasks: Library<Task> = JSON.parse(JSON.stringify(playbook.tasks)) || {};

        newTasks[new Date().getTime()] = {
            title: 'New Task',
            order: taskList.length,
            details: 'Add Details',
        }

        setPlaybook({ ...playbook, tasks: newTasks });
        // Task List updated via Effect...
    }

    function removeTask(taskId: string | number) {
        const newTasks: Library<Task> = JSON.parse(JSON.stringify(playbook.tasks)) || {};
        delete newTasks[taskId];
        setPlaybook({ ...playbook, tasks: newTasks });
        // Task List updated via Effect...
    }

    async function savePlaybook() {
        setLoading(true);

        if (!playbook.name) {
            alert("Can't Playbook without a Name!");
        } else {
            // Add slug to playbook if needed
            if (!playbook.slug) {
                const slugify = slugifyWithCounter();
                playbook.slug = slugify(playbook.name);
            }

            const newPlaybook: Playbook = JSON.parse(JSON.stringify(playbook));

            // Loop through all tasks once load slugify with the keys we already have...
            const slugify = slugifyWithCounter();
            for (const taskId of Object.keys(newPlaybook.tasks)) {
                slugify(taskId);
            }

            // Loop through again, this time using taskList so we can get the order
            for (const [i, item] of taskList.entries()) {
                newPlaybook.tasks[item.id] = newPlaybook.tasks[item.id] || {};
                newPlaybook.tasks[item.id].order = i;

                // Update task IDs for new tasks to slug...
                if (!isNaN(item.id as number)) {
                    const tempTask: Task = newPlaybook.tasks[item.id];
                    const newId = slugify(tempTask.title);
                    newPlaybook.tasks[newId] = tempTask;
                    delete newPlaybook.tasks[item.id];
                }
            }

            setPlaybook(newPlaybook);

            const res = await updatePlaybook(newPlaybook);

            if (res.ok) {
                setShowAlert(<Alert className="mb-100" onClose={() => setShowAlert(<></>)} severity="success">Your Playbook has been saved!</Alert>)
            } else {
                console.log(res.err);
                setShowAlert(<Alert className="mb-100" onClose={() => setShowAlert(<></>)} severity="error">There was an issue saving your playbook...</Alert>)
            }
        }

        setLoading(false);
    }

    return (
        <>
            <Layout>
                {showAlert}
                <button className="btn btn-primary mb-100" onClick={savePlaybook}>
                    {loading ? 'Saving...' : 'Save Playbook'}
                </button>

                {playbook ? (
                    <>
                        <div className="form-group mb-100">
                            <label>Playbook Name <span className="hint">&ndash; {playbook?.slug}/{playbook?.id}</span></label>
                            <input value={playbook?.name || ""} onChange={(e) => setPlaybook({ ...playbook, name: e.target.value })} />
                        </div>

                        <div className="form-group mb-100">
                            <label>Playbook Description</label>
                            <input value={playbook?.description || ""} onChange={(e) => setPlaybook({ ...playbook, description: e.target.value })} />
                        </div>
                    </>
                ) : null}

                <h3 className="mb-100">Tasks</h3>

                <ReactSortable list={taskList} setList={handleReorder}>
                    {playbook.tasks && taskList?.map((item, i) => (
                        <div className="card mb-100" key={item.id}>
                            {playbook.tasks[item.id] ? (
                                <>
                                    <input value={playbook.tasks[item.id]?.title} onChange={(e) => updateTasks(item.id, "title", e.target.value)} />
                                    <div>ID: {item.id}</div>
                                    <Tiptap content={playbook.tasks[item.id].details} onChange={(content) => updateTasks(item.id, "details", content)} />
                                    {/* <textarea value={playbook.tasks[item.id]?.details} onChange={(e) => updateTasks(item.id, "details", e.target.value)} /> */}
                                </>
                            ) : null}




                            <button className="mt-100" onClick={() => removeTask(item.id)}>Remove</button>
                        </div>
                    ))}
                </ReactSortable>

                {/* <Tiptap content={testContent} onChange={setTestContent} /> */}
                <button onClick={addTask}>+ Add Task</button>
            </Layout>
        </>
    );
}

export default EditPlaybookPage;