import { mergeAttributes, Node } from '@tiptap/core';

function parseLoomEmbedCode(embedCode: string) {
    const temp = document.createElement("div");
    temp.innerHTML = embedCode;
    const wrapper = temp.firstElementChild;
    const iframe = wrapper?.firstElementChild;

    if (wrapper && iframe) {
        const wrapperAttrs: Record<string, string> = wrapper.getAttributeNames().reduce((acc, name) => {
            return { ...acc, [name]: wrapper?.getAttribute(name) };
        }, {});

        const iframeAttrs: Record<string, string> = iframe.getAttributeNames().reduce((acc, name) => {
            return { ...acc, [name]: iframe?.getAttribute(name) };
        }, {});

        return {
            src: iframeAttrs['src'],
            wrapperStyle: wrapperAttrs['style'],
        }
    } else {
        return {};
    }
}


export interface LoomOptions {
    HTMLAttributes: Record<string, any>,
    src?: string,
}

declare module '@tiptap/core' {
    interface Commands<ReturnType> {
        loom: {
            /**
             * Insert a Loom video
             */
            setLoomVideo: (options: { 'data-embed-code': string, }) => ReturnType,
        }
    }
}

export const LoomVideo = Node.create<LoomOptions>({
    name: 'loom',

    addOptions() {
        return {
            src: '',
            HTMLAttributes: {},
        }
    },

    group() {
        return 'block';
    },

    draggable: true,

    addAttributes() {
        return {
            'data-embed-code': {
                default: null,
            },

            src: {
                default: null,
                parseHTML: element => {
                    return element.children[0]?.children[0]?.getAttribute('src');
                }
            },
            style: {
                default: 'position: absolute; top: 0; left: 0; width: 100%; height: 100%;',
                parseHTML: element => {
                    return element.getAttribute('style')
                }
            },
            frameBorder: {
                default: 0,
            },
            webkitallowfullscreen: {
                default: true,
            },
            mozallowfullscreen: {
                default: true,
            },
            allowfullscreen: {
                default: true,
            },
        }
    },
    parseHTML() {
        return [
            {
                tag: 'section[data-loom-video]',
            }
        ]
    },

    addCommands() {
        return {
            setLoomVideo: options => ({ commands }) => {
                const parsedEmbedCode = parseLoomEmbedCode(options['data-embed-code']);
                if (!parsedEmbedCode.src || !parsedEmbedCode.wrapperStyle) {
                    alert("BAD EMBED CODE");
                    return false;
                }

                return commands.insertContent({
                    type: this.name,
                    attrs: options,
                })
            },
        }
    },

    // addPasteRules() {
    //     if (!this.options.addPasteHandler) {
    //         return []
    //     }

    //     return [
    //         nodePasteRule({
    //             find: YOUTUBE_REGEX_GLOBAL,
    //             type: this.type,
    //             getAttributes: match => {
    //                 return { src: match.input }
    //             },
    //         }),
    //     ]
    // },

    renderHTML({ HTMLAttributes, node }) {
        const parsedEmbedCode = parseLoomEmbedCode(HTMLAttributes['data-embed-code']);

        return [
            'section',
            { 'data-loom-video': '', 'data-embed-code': HTMLAttributes['data-embed-code'] },
            ['div',
                mergeAttributes(
                    { style: parsedEmbedCode.wrapperStyle },
                ),
                [
                    'iframe',
                    mergeAttributes(
                        this.options.HTMLAttributes,
                        {
                            src: parsedEmbedCode.src,
                            frameBorder: HTMLAttributes.frameBorder,
                            webkitallowfullscreen: HTMLAttributes.webkitallowfullscreen,
                            mozallowfullscreen: HTMLAttributes.mozallowfullscreen,
                            allowfullscreen: HTMLAttributes.allowFullscreen,
                            style: HTMLAttributes.style,
                        }
                    ),
                ],
            ]]
    },
})