import React from 'react';
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { analyticsService } from "../services/analytics";

// NOTE: Able to remove this as a dependency from useEffect if you include outside the component
// BECAUSE it's not really a hook, so we shouldn't name it that way
// https://reactjs.org/docs/hooks-faq.html#is-it-safe-to-omit-functions-from-the-list-of-dependencies

const { logScreen } = analyticsService();

const PageAnalytics: React.FC<any> = (props) => {
    const location = useLocation();

    useEffect(() => {
        let path = location.pathname.split("/")[1].length ? location.pathname.split("/")[1] : "/";
        logScreen(path, location.pathname, null);
    }, [location.pathname, props.appInfo]);

    return (<></>);
};

export default PageAnalytics;