import React, { useState, useEffect } from 'react';
import { useFirebaseAuth } from '../services/firebase';
import { Link } from 'react-router-dom';
// import LayoutOnboarding from '../components/LayoutOnboarding';
// import './SignIn.scss';
import "./LandingPage.scss";

function FreelanceProgrammerLP(props: any) {
    const { signUp } = useFirebaseAuth();
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');

    const [msg, setMsg] = useState<string>('');

    useEffect(() => {
        setMsg('');
    }, [])

    // async function doSignIn(e: any) {
    //     e.preventDefault();
    //     if (email.length && password.length) {
    //         let res: any = await signIn(email, password);
    //         // console.log('signIn res', res);

    //         if (res.code && res.code === "auth/user-not-found") {
    //             setMsg("This user doesn't exist. Check that you entered the correct email or create a new account.")
    //         } else if (res.code && res.code === "auth/wrong-password") {
    //             setMsg("The password you entered is invalid. Please try again.");
    //         } else if (res.code) {
    //             setMsg("An error occured");
    //         }

    //     } else {
    //         setMsg("Please enter a valid email and password.");
    //     }
    // }

    async function doSignUp(e: any) {
        e.preventDefault();
        if (email.length && password.length) {
            let res: any = await signUp(email, password);

            if (res.code && res.code === "auth/email-already-in-use") {
                setMsg("A user with this email address already exists. Try logging in.");
            } else if (res.code) {
                setMsg("An error occured");
                console.log("Error", res);
            }
        } else {
            setMsg("Please enter a valid email and password.");
        }
    }

    // async function doReset(e: any) {
    //     e.preventDefault();
    //     if (email.length) {
    //         let res: any = await resetPassword(email);
    //         console.log('doReset res', res);

    //         if (typeof res === 'undefined') {
    //             setMsg("Check your email for a reset link.");
    //         } else if (res.code === "auth/user-not-found") {
    //             setMsg("This user doesn't exist. Check your email or create a new account.")
    //         } else {
    //             setMsg("An error occured");
    //         }
    //     } else {
    //         setMsg("Please enter a valid email.");
    //     }
    // }

    return (
        <div className="LandingPage">
            <div className="row-fluid">

                <div className="col col-md-6">
                    <div className="mb-250">
                        <div className="pre-header">Free Course</div>
                    </div>
                    <img src="/logo.png" alt="FreelanceGPS Logo" className="mb-250" />
                    <h1 className="mb-250">Grow Your Freelance Dev Business</h1>

                    <div className="highlight mb-50">Work from anywhere.</div>
                    <div className="highlight mb-250">Financial and creative freedom.</div>

                    <div className="mb-100"><strong>Here's what you'll learn:</strong></div>

                    <ul>
                        <li>Is freelance programming right for you?</li>
                        <li>How to Prioritize YOUR LIFE Over Work</li>
                        <li>How much you can earn</li>
                        <li>How to market your freelancing</li>
                    </ul>
                </div>
                <div className="col col-md-6 bg-col">
                    <div className="LandingSignIn">
                        {/* onSubmit={(e) => signupMode ? doSignUp(e) : doSignIn(e)} */}
                        <form className="card" onSubmit={(e) => doSignUp(e)}>
                            <h2 className="mb-100 text-center">Sign Up</h2>
                            {msg ? <div className="mb-100 font-red">{msg}</div> : null}
                            <input placeholder="Email" type="email" onChange={(e) => setEmail(e.target.value)} required />
                            <input placeholder="Password" type="password" onChange={(e) => setPassword(e.target.value)} required />
                            <input className="btn btn-primary btn-block" type="submit" value="Sign Up" />
                            <div className="text-center mt-100">
                                Already a member? <Link to="/" className="link">Log In</Link>
                            </div>
                            {/* {signupMode ? null : <span className="change-mode-link mt-50" onClick={doReset}>Forgot Password?</span>} */}
                        </form>

                        {/* <div>
                    {signupMode ?
                        <div>
                            Already a member? <span className="change-mode-link" onClick={() => setSignupMode(!signupMode)}>Log In</span>
                        </div>

                        : <div>
                            Don't Have an Account? <span className="change-mode-link" onClick={() => setSignupMode(!signupMode)}>Sign Up</span>
                        </div>
                    }
                </div> */}

                    </div>
                </div>


            </div>

        </div>
    );
}

export default FreelanceProgrammerLP;