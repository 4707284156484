import { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import Layout from "../components/Layout";
import { getPlaybook, updateUser, UserContext } from "../services/firebase";
import { Playbook } from "../../shared/types";
import { analyticsService } from "../services/analytics";

import { createListFromOrderedItems } from "../services/gpsUtil";
import CheckIcon from '@mui/icons-material/Check';
import LinkIcon from '@mui/icons-material/Link';

import './PlaybookPage.scss';
import { IconButton } from "@mui/material";

const { logEvent } = analyticsService();

function PlaybookPage() {
    const { id, taskId } = useParams();
    const user = useContext(UserContext);

    const [playbook, setPlaybook] = useState<Playbook>();
    const [selectedTaskId, setSelectedTaskId] = useState<string>();

    useEffect(() => {
        getInitialProps();

        async function getInitialProps() {
            if (id) {
                const res = await getPlaybook(id);

                if (res.ok) {
                    setPlaybook(res.val);

                    // Select the first incomplete task...
                    const taskList = createListFromOrderedItems(res.val.tasks);
                    const incompleteTasks = taskList.filter((task) => !(user?.playbookStatus?.[id]?.tasks?.[task.id]));

                    if (taskId && taskList.map((item) => item.id).includes(taskId)) {
                        setSelectedTaskId(taskId);
                    } else {
                        setSelectedTaskId(incompleteTasks.length ? incompleteTasks[0].id : taskList[0].id);
                    }
                }
            }
        }
    }, [id, user, taskId]);

    function handleCheckbox(target: HTMLInputElement, taskId: string) {
        if (user && id) {
            const isChecked = target.checked;

            const newPlaybookStatus = user?.playbookStatus || {};
            newPlaybookStatus[id] = newPlaybookStatus[id] || {};
            newPlaybookStatus[id].tasks = newPlaybookStatus[id].tasks || {};
            newPlaybookStatus[id].tasks![taskId] = isChecked;

            if (isChecked) {
                logEvent("Completed Task", {
                    taskOrder: playbook?.tasks?.[taskId]?.order,
                    taskId: taskId,
                    playbookId: id,
                    playbookName: playbook?.name,
                    playbookSlug: playbook?.slug,
                    domain: window.location.hostname || '',
                    url: window.location.pathname,
                });
            } else {
                logEvent("Incompleted Task", {
                    taskOrder: playbook?.tasks?.[taskId]?.order,
                    taskId: taskId,
                    playbookId: id,
                    playbookName: playbook?.name,
                    playbookSlug: playbook?.slug,
                    domain: window.location.hostname || '',
                    url: window.location.pathname,
                });
            }

            updateUser({ ...user, playbookStatus: newPlaybookStatus });
        }
    }

    function handleButtonComplete(taskId: string) {
        if (user && id) {
            const newPlaybookStatus = user?.playbookStatus || {};
            newPlaybookStatus[id] = newPlaybookStatus[id] || {};
            newPlaybookStatus[id].tasks = newPlaybookStatus[id].tasks || {};
            newPlaybookStatus[id].tasks![taskId] = true;

            logEvent("Completed Task", {
                taskOrder: playbook?.tasks?.[taskId]?.order,
                taskId: taskId,
                playbookId: id,
                playbookName: playbook?.name,
                playbookSlug: playbook?.slug,
            });

            updateUser({ ...user, playbookStatus: newPlaybookStatus });
        }
    }

    function handleSelect(taskId: string) {
        // console.log(taskId);
        logEvent("Selected Task", {
            taskOrder: playbook?.tasks?.[taskId]?.order,
            taskId: taskId,
            playbookId: id,
            playbookName: playbook?.name,
            playbookSlug: playbook?.slug,
        });

        if (selectedTaskId === taskId) {
            setSelectedTaskId('');
        } else {
            setSelectedTaskId(taskId);
        }
    }


    return (
        <>
            <Layout>
                <div className="PlaybookPage">
                    <div className="container-fluid mb-200">
                        <h1 className="mb-100">{playbook?.name}</h1>
                        <div>{playbook?.description}</div>
                    </div>
                    <div className="row-fluid">
                        <div className="col-md-4">
                            <div className="PlaybookCard">
                                {playbook?.tasks ? (createListFromOrderedItems(playbook.tasks)).map((task) => (
                                    <section key={task.id} className={selectedTaskId === task.id ? "selected task" : "task"}>
                                        <div className={user?.playbookStatus?.[id!]?.tasks?.[task.id] ? "task-line checked" : "task-line"} key={task.id}>
                                            <input type="checkbox" checked={user?.playbookStatus?.[id!]?.tasks?.[task.id] ? true : false} onChange={(e) => handleCheckbox(e.target as HTMLInputElement, task.id)} />
                                            {/* <div className={user?.playbookStatus?.[id!]?.tasks?.[task.id] ? "checked flex align-items-center" : "flex align-items-center"}>
                                        </div> */}
                                            <div className="task-clickable" onClick={() => handleSelect(task.id)}>
                                                <div className="task-title">{task.title}</div>
                                                {/* hidden-sm-down */}
                                                <div className="view-task">{selectedTaskId === task.id ? "Hide" : "View"}</div>
                                            </div>
                                        </div>

                                        <div className={selectedTaskId !== task.id ? "task-body hidden" : "task-body hidden-md-up"}>
                                            <div dangerouslySetInnerHTML={{ __html: task.details }} />

                                            {
                                                id && !user?.playbookStatus?.[id]?.tasks?.[task.id] ? (
                                                    <div className="mt-100 text-center">
                                                        <button className="btn btn-primary" onClick={() => handleButtonComplete(task.id)}>
                                                            <CheckIcon className="mr-50" />
                                                            Mark Complete
                                                        </button>
                                                    </div>
                                                ) : null
                                            }
                                        </div>
                                    </section>

                                )) : null}
                            </div>
                        </div>

                        <div className="col-md-8 hidden-sm-down">
                            {playbook?.tasks && selectedTaskId ? (
                                <section className="PlaybookCard card">
                                    <h2 className="mb-100 flex align-items-center justify-content-between">
                                        {playbook.tasks[selectedTaskId].title}
                                        <IconButton onClick={() => navigator.clipboard.writeText(`${window.location.hostname === 'localhost' ? 'http' : 'https'}://${window.location.host}/${playbook.slug}/${playbook.id}/${selectedTaskId}`)}>
                                            <LinkIcon />
                                        </IconButton>
                                    </h2>
                                    <div dangerouslySetInnerHTML={{ __html: playbook.tasks[selectedTaskId].details }} />

                                    {
                                        id && !user?.playbookStatus?.[id]?.tasks?.[selectedTaskId] ? (
                                            <div className="mt-100 text-center">
                                                <button className="btn btn-primary" onClick={() => handleButtonComplete(selectedTaskId)}>
                                                    <CheckIcon className="mr-50" />
                                                    Mark Complete
                                                </button>
                                            </div>
                                        ) : null
                                    }
                                </section>
                            ) : null}

                        </div>
                    </div>
                </div>
            </Layout>
        </>
    );
}

export default PlaybookPage;