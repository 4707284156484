import amplitude from "amplitude-js";
import ReactGA from 'react-ga';

const amplitudeClient = amplitude.getInstance();
amplitudeClient.init(process.env.REACT_APP_AMPLITUDE_KEY!, undefined, { includeReferrer: true, includeUtm: true, includeGclid: true });

export function analyticsService() {
    const logEvent = (eventName: string, eventProps?: object, gaEventOverride?: { category?: string, action: string, label?: string, value?: number }) => {
        amplitudeClient.logEvent(eventName, {
            ...eventProps,
            domain: window.location.hostname || '',
            url: window.location.pathname,
            version: process.env.REACT_APP_VERSION || '',
        });

        if (gaEventOverride) {
            ReactGA.event({
                category: gaEventOverride.category || 'engagement',
                action: gaEventOverride.action,
                label: gaEventOverride.label || gaEventOverride.action,
                value: gaEventOverride.value || 0,
            });
        } else {
            ReactGA.event({
                category: 'engagement',
                action: eventName,
                label: eventName,
            });
        }
    };

    const logScreen = (screenName: string, url: string, appInfo?: any) => {
        amplitudeClient.logEvent("Viewed Page", {
            screenName: screenName,
            url: url,
            domain: window.location.hostname || '',
            version: process.env.REACT_APP_VERSION || '',
            ...appInfo,
        });

        ReactGA.pageview(window.location.pathname + window.location.search);
    };

    const setUserProperty = (propName: string, propValue: any) => {
        var property = new amplitude.Identify().set(propName, propValue);
        amplitudeClient.identify(property);
    };

    const setUserId = (id: string) => {
        amplitudeClient.setUserId(id);
    };

    const logOut = () => {
        amplitudeClient.setUserId(null); // not string 'null'
        amplitudeClient.regenerateDeviceId();
    }

    return { logEvent, logScreen, setUserProperty, setUserId, logOut };
}