import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { useFirebaseAuth, UserContext } from './services/firebase';
import ReactGA from 'react-ga';

// Pages
import SignIn from './pages/SignIn';
import Main from './pages/Main';
import PlaybookPage from "./pages/PlaybookPage";
import EditPlaybookPage from "./pages/EditPlaybookPage";
import EditUserPage from "./pages/EditUserPage";

import './theme/index.scss';
import FreelanceProgrammerLP from "./pages/FreelanceProgrammerLP";
import FreelanceJumpstartLP from "./pages/FreelanceJumpstartLP";

//
import { CircularProgress } from "@mui/material";
import PageAnalytics from "./components/PageAnalytics";

ReactGA.initialize('UA-209755088-2');

function App() {
    const { user, userAuthed, userLoading } = useFirebaseAuth();

    // const protectedRoutes = () => {
    //     if (userLoading) {
    //         return (<>Loading...</>);
    //     } else if (userAuthed?.uid?.length) {
    //         return (
    //             <>
    //                 <UserContext.Provider value={user}>
    //                     <Routes>
    //                         <Route path="/" element={<Main />} />
    //                         <Route path="/:slug/:id" element={<PlaybookPage />} />
    //                         <Route path="/edit/:slug/:id" element={<EditPlaybookPage />} />
    //                         <Route path="/edit/user/:id" element={<EditUserPage />} />
    //                     </Routes>
    //                 </UserContext.Provider>
    //             </>
    //         )

    //     } else {
    //         return (
    //             <Routes>
    //                 <Route path="/freelance-programmer" element={<FreelanceProgrammerLP />} />
    //                 <Route path="/" element={<SignIn />} />
    //             </Routes>
    //         )
    //     }
    // }

    return (
        <div className="App">
            {/* <ThemeContext.Provider value={theme}> */}
            {/* <HelmetProvider> */}
            {/* <Helmet>
                    <link rel="icon" href={theme.faviconUrl} />
                </Helmet> */}
            {/* <div className={'App ' + theme.themeClass}> */}
            {/* <BrowserRouter>
        {protectedRoutes()}
      </BrowserRouter> */}
            {/* </div> */}
            {/* </HelmetProvider>
        </ThemeContext.Provider> */}
            <UserContext.Provider value={user}>
                <BrowserRouter>
                    <PageAnalytics />
                    <Routes>
                        {userLoading ? (<Route path="/" element={<div className="AppLoading"><CircularProgress sx={{ color: '#11B2FA' }} /></div>} />) : (
                            <>
                                {userAuthed?.uid ? (
                                    // AUTHED
                                    <Route path="/">
                                        <Route index element={<Main />} />
                                        <Route path="/:slug/:id/" element={<PlaybookPage />}>
                                            <Route path=":taskId" element={<PlaybookPage />} />
                                        </Route>
                                        <Route path="/edit/:slug/:id" element={<EditPlaybookPage />} />
                                        <Route path="/edit/user/:id" element={<EditUserPage />} />
                                        <Route path="*" element={<Navigate to="/" />} />
                                    </Route>
                                ) : (
                                    // PUBLIC
                                    <Route path="/">
                                        <Route index element={<SignIn />} />
                                        <Route path="/freelance-programmer" element={<FreelanceProgrammerLP />} />
                                        <Route path="/freelance-jumpstart" element={<FreelanceJumpstartLP />} />
                                        <Route path="*" element={<SignIn />} />
                                    </Route>
                                )}
                            </>
                        )}
                        {/* SHARED ROUTES */}
                    </Routes>
                </BrowserRouter>
            </UserContext.Provider>
        </div>
    );
}

export default App;
