import { useContext, useEffect, useState } from 'react';
import Layout from '../components/Layout';
// import { Helmet } from 'react-helmet-async';
// import { ThemeContext } from '../theme/ThemeContext';
import { Link, useNavigate } from 'react-router-dom';
import { getPlaybooksByIds, UserContext, createPlaybook, getAllPlaybooks } from '../services/firebase';
import { Playbook } from '../../shared/types';
import { sortItemList } from '../services/gpsUtil';
import { analyticsService } from "../services/analytics";

import './Main.scss';
import PlaybookCard from '../components/PlaybookCard';
import CircularProgress from '@mui/material/CircularProgress';

const { logEvent } = analyticsService();

function Main() {
    const [playbooks, setPlaybooks] = useState<Playbook[]>();
    const user = useContext(UserContext);
    const navigate = useNavigate();
    const [retry, setRetry] = useState(0);

    useEffect(() => {
        getInitialProps();

        async function getInitialProps() {
            if (user?.roles?.admin) {
                const res = await getAllPlaybooks();

                if (res.ok) {
                    setPlaybooks(res.val);
                } else {
                    console.log(`ERROR`, res.err);
                }
            } else if (user?.playbookStatus) {
                const res = await getPlaybooksByIds(Object.keys(user.playbookStatus));

                if (res.ok) {
                    const newPlaybooks = sortItemList(res.val.map((playbook) => {
                        return {
                            ...playbook,
                            order: user?.playbookStatus![playbook.id].order
                        }
                    }));

                    setPlaybooks(newPlaybooks as Playbook[]);
                } else {
                    // Weird hack to wait for Firebase permissions to catch up...
                    if (retry < 10) {
                        setTimeout(() => {
                            setRetry(retry + 1);
                        }, 1000 + (retry * 500))
                    } else {
                        console.log(`ERROR`, res.err);
                    }
                }
            }
        }
    }, [user, user?.playbookStatus, retry])

    async function addPlaybook() {
        const res = await createPlaybook();

        if (res.ok) {
            navigate(`/edit/new/${res.val}`);
        }
    }

    return (
        <div className="MainPage">
            {/* <Helmet>
                <title>Reports | {theme.orgName}</title>
            </Helmet> */}
            <Layout>
                <div className="row mt-300">

                    {user?.roles?.admin ? (
                        <div className='col-12'>
                            <div className="flex justify-content-between mb-100">
                                <h1 className="mb-100">Hello!</h1>
                                {user?.roles?.admin ? <button className="btn btn-primary" onClick={addPlaybook}>+ New Playbook</button> : null}
                            </div>

                            {playbooks?.map((playbook) => (
                                <div key={playbook.id}>
                                    <Link onClick={() => logEvent("Clicked Playbook")} className="link" to={`/${playbook.slug}/${playbook.id}`}>{playbook.name}</Link>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <>
                            {
                                playbooks?.length ? (
                                    <>
                                        <div className="start-lg-4 col-lg-6">
                                            {playbooks?.map((playbook) => (
                                                <Link to={`/${playbook.slug}/${playbook.id}`} key={playbook.id}>
                                                    <PlaybookCard playbook={playbook} status={user?.playbookStatus?.[playbook.id]} />
                                                </Link>
                                            ))}

                                            {playbooks.length === 1 ? (
                                                <>
                                                    <div className="text-center mt-300 mb-300">
                                                        <h2 className="mb-100">Free Strategy Session</h2>
                                                        <div className="mb-100">Wondering how to build your freelance business?</div>
                                                        <div><a onClick={() => logEvent('Clicked Strategy Session')} className="link" href="https://form.questionscout.com/5f344a9775e69e4dead639d4" target="_blank" rel="noreferrer">Book a free strategy session with me</a> and I'll lay out a personalized roadmap to help you reach your dreams.</div>
                                                    </div>

                                                </>
                                            ) : null}
                                        </div>
                                    </>
                                ) : <div className="AppLoading"><CircularProgress sx={{ color: '#11B2FA' }} /></div>
                            }

                        </>
                    )}
                </div>
            </Layout>
        </div>
    );
}

export default Main;