import { initializeApp } from 'firebase/app';
import { createUserWithEmailAndPassword, getAdditionalUserInfo, getAuth, sendPasswordResetEmail, signInWithEmailAndPassword, signOut, UserCredential } from "firebase/auth";
import { collection, doc, getDoc, getDocs, getFirestore, onSnapshot, setDoc } from "firebase/firestore";
import { createContext, useEffect, useState } from 'react';
import { useAuthState } from "react-firebase-hooks/auth";
import { Playbook, Result, User } from '../../shared/types';
import { analyticsService } from "./analytics";

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

initializeApp(firebaseConfig);
const auth = getAuth();
const db = getFirestore();

export const UserContext = createContext<User | undefined>(undefined);
UserContext.displayName = "UserContext"; // https://reactjs.org/docs/context.html#contextdisplayname

export function useFirebaseAuth() {
    const [userAuthed, userLoading, userError] = useAuthState(auth);

    const [user, setUser] = useState<User>();

    useEffect(() => {
        if (!userLoading && userAuthed?.uid) {
            const unsubscribe = onSnapshot(doc(db, "users", userAuthed.uid), (doc) => {
                // console.log("Current data: ", doc.data());
                setUser({
                    ...doc.data(),
                    id: userAuthed.uid,
                });
            });

            // // https://blog.logrocket.com/understanding-react-useeffect-cleanup-function/
            // // https://benmcmahen.com/using-firebase-with-react-hooks/
            return () => unsubscribe()
        }
    }, [userLoading, userAuthed]);


    const signIn = async (email: string, password: string) => {
        try {
            const res = await signInWithEmailAndPassword(auth, email, password);

            analyticsService().logEvent("Logged In", undefined, { action: 'login' });
            analyticsService().setUserId(res.user!.uid);

            return res;
        } catch (e) {
            return e;
        }
    };

    const signUp = async (email: string, password: string) => {
        try {
            const res: UserCredential = await createUserWithEmailAndPassword(auth, email, password);

            console.log(`res`, res);

            const additionalUserInfo = getAdditionalUserInfo(res);
            console.log(`additionalUserInfo`, additionalUserInfo);

            if (window && window.gtag) {
                try {
                    window.gtag!('event', 'conversion', { 'send_to': 'AW-1059300074/eEmSCP3ggd8DEOrFjvkD' });
                } catch (e) {
                    console.log(`gtag error`, e);
                }

            }

            if (additionalUserInfo?.isNewUser) {
                await addNewUserInfo(res.user.uid, {
                    email: email,
                    id: res.user.uid,
                    playbookStatus: {
                        "7sQwXitnbjP67fpCxkFL": {
                            "order": 0,
                            "tasks": {}
                        }
                    }
                });
            } else {
                await addNewUserInfo(res.user.uid, {
                    email: email,
                    id: res.user.uid,
                });
            }

            analyticsService().logEvent("Signed Up", undefined, { action: 'sign_up' });
            analyticsService().setUserId(res.user!.uid);
            analyticsService().setUserProperty('email', email);

            return res;
        } catch (e) {
            return e;
        }
    }

    const resetPassword = async (email: string) => {
        try {
            let res = await sendPasswordResetEmail(auth, email);
            analyticsService().logEvent("Requested New Password", { email });
            return res;
        } catch (e) {
            return e;
        }
    }

    const doSignOut = () => {
        signOut(auth);
        analyticsService().logEvent("Signed Out", undefined, { action: 'sign_out' });
        analyticsService().logOut();

        // setTimeout(function () {
        //     // reload the page after logout to properly handle drift...
        //     window.location.href = JSON.stringify(window.location.href);
        // }, 500);
    };

    return { user, userAuthed, userLoading, userError, signIn, signUp, doSignOut, resetPassword };
}

// TJN TO-DO... use Result type...
export async function addNewUserInfo(uid: string, newUserInfo: any) {
    const res = await setDoc(doc(db, "users", uid), {
        ...newUserInfo
    }, { merge: true });

    console.log(`addNewUser res`, res);
}

export async function getPlaybook(playbookId: string): Promise<Result<Playbook>> {
    try {
        const res = await getDoc(doc(db, "playbooks", playbookId));

        if (res.exists()) {
            return {
                ok: true,
                val: res.data() as Playbook
            }
        } else {
            return {
                ok: false,
                err: 'Playbook Not Found'
            }
        }
    } catch (e) {
        return {
            ok: false,
            err: e,
        }
    }
}

export async function getPlaybooksByIds(playbookIds: string[]): Promise<Result<Playbook[]>> {
    try {
        const playbookPromises = [];

        for (const playbookId of playbookIds) {
            playbookPromises.push(getDoc(doc(db, "playbooks", playbookId)));
        }

        const playbooks = (await Promise.all(playbookPromises))
            .filter((snap) => snap.exists())
            .map((snap) => snap.data() as Playbook);

        return {
            ok: true,
            val: playbooks
        }
    } catch (e) {
        return {
            ok: false,
            err: e,
        }
    }

}

export async function getAllPlaybooks(): Promise<Result<Playbook[]>> {
    try {
        const querySnapshot = await getDocs(collection(db, "playbooks"));
        const playbooks: Playbook[] = querySnapshot.docs.map((doc) => {
            return {
                ...doc.data() as Playbook,
                // id: doc.id,
            }
        });

        return {
            ok: true,
            // val: 'foo'
            val: playbooks,
        }
    } catch (e) {
        return {
            ok: false,
            err: e,
        }
    }
}

export async function updatePlaybook(playbook: Playbook): Promise<Result<void>> {
    console.log(`playbookId`, playbook.id);
    console.log(`playbook`, playbook);

    try {
        // No need to use { merge: true } for playbooks... just fully overwrite, because changes are only coming from one place
        await setDoc(doc(db, "playbooks", playbook.id), {
            ...playbook
        });

        return {
            ok: true,
            val: undefined,
        }
    } catch (e) {
        console.log(`error in firebase`, e);
        return {
            ok: false,
            err: e,
        }
    }
}

export async function createPlaybook(playbook?: Playbook): Promise<Result<string>> {
    try {
        const newPlaybookRef = doc(collection(db, "playbooks"));
        await setDoc(newPlaybookRef, { ...playbook, id: newPlaybookRef.id, tasks: {} });

        return {
            ok: true,
            val: newPlaybookRef.id
        }
    } catch (e) {
        return {
            ok: false,
            err: e
        }
    }
}

// export async function getUserById(uid: string): Promise<Result<User>> {
//     try {
//         const res = await getDoc(doc(db, "users", uid));

//         if (res.exists()) {
//             return {
//                 ok: true,
//                 val: res.data() as Playbook
//             }
//         } else {
//             return {
//                 ok: false,
//                 err: 'User Not Found'
//             }
//         }
//     } catch (e) {
//         return {
//             ok: false,
//             err: e,
//         }
//     }
// }

export async function updateUser(user: User): Promise<Result<void>> {
    try {
        // No need to use merge for users... will always use user stream for users, so sets will have latest data...
        const res = await setDoc(doc(db, "users", user.id), { ...user }); // { merge: true }
        console.log(`updateUser`, res);

        return {
            ok: true,
            val: undefined
        }

    } catch (e) {
        return {
            ok: false,
            err: e,
        }
    }
}

export function useUser(uid: string) {
    const [user, setUser] = useState<User>();

    useEffect(() => {
        // if (!userLoading && userAuthed?.uid) {
        const unsubscribe = onSnapshot(doc(db, "users", uid), (doc) => {
            console.log("Current data from useUser: ", doc.data());
            setUser({
                ...doc.data(),
                id: uid,
            });
        });

        // // https://blog.logrocket.com/understanding-react-useeffect-cleanup-function/
        // // https://benmcmahen.com/using-firebase-with-react-hooks/
        return () => unsubscribe()
    }, [uid]);

    return user;
}