import { useEditor, EditorContent } from '@tiptap/react'
import { LoomVideo } from '../services/LoomVideo';
import Image from '@tiptap/extension-image';
import StarterKit from '@tiptap/starter-kit'
import Link from '@tiptap/extension-link';
import './Tiptap.scss';
import { useCallback, useEffect } from 'react';

// Import 1 by 1 for Tree-Shaking
import FormatBold from '@mui/icons-material/FormatBold';
import FormatItalic from '@mui/icons-material/FormatItalic';
import Code from '@mui/icons-material/Code';
import HorizontalRule from '@mui/icons-material/HorizontalRule';
import FormatListNumbered from '@mui/icons-material/FormatListNumbered';
import FormatStrikethrough from '@mui/icons-material/FormatStrikethrough';
import FormatListBulleted from '@mui/icons-material/FormatListBulleted';
import FormatQuote from '@mui/icons-material/FormatQuote';
import KeyboardReturn from '@mui/icons-material/KeyboardReturn';
import Undo from '@mui/icons-material/Undo';
import Redo from '@mui/icons-material/Redo';
import LinkIcon from '@mui/icons-material/Link';
import FormatClear from '@mui/icons-material/FormatClear';
import PhotoIcon from '@mui/icons-material/Photo';
import SvgIcon from '@mui/material/SvgIcon'; // custom icons

interface TiptapInterface {
    content: string,
    onChange: (content: string) => unknown,
    // task: Task
}

function Tiptap(props: TiptapInterface) {
    const { content, onChange } = props;
    // 
    // const initialContent = useMemo(() => content, []);
    // const initialContent = useMemo(() => content, [content]);

    // const initialContent = JSON.parse(JSON.stringify(content));

    // const theTask = useMemo(() => task, [task]);

    // const safeTask = JSON.parse(JSON.stringify(task));
    // delete safeTask.details;

    const editor = useEditor({
        extensions: [
            StarterKit,
            Link.configure({
                openOnClick: false,
            }),
            LoomVideo,
            Image.configure({
                inline: true,
            })
        ],
        content: content,
    });


    // DO THIS INSTEAD OF using onUpdate in useEditor: https://github.com/ueberdosis/tiptap/issues/2403#issuecomment-1017840603
    useEffect(() => {
        editor?.off("update");
        editor?.on("update", ({ editor: updatedEditor }) => onChange(updatedEditor.getHTML()));
    }, [editor, onChange]);

    const setLink = useCallback(() => {
        const previousUrl = editor?.getAttributes('link').href
        const url = window.prompt('URL', previousUrl)

        // cancelled
        if (url === null) {
            return
        }

        // empty
        if (url === '') {
            editor?.chain().focus().extendMarkRange('link').unsetLink()
                .run()

            return
        }

        // update link
        editor?.chain().focus().extendMarkRange('link').setLink({ href: url })
            .run()
    }, [editor]);

    const addLoomVideo = useCallback(() => {
        const embedCode = window.prompt(
            'Loom Embed Code',
            '<div ...><iframe ...></iframe></div>'
        );
        if (embedCode) {
            editor?.commands.setLoomVideo({
                'data-embed-code': embedCode
            })
        }

    }, [editor]);

    const addImage = useCallback(() => {
        const url = window.prompt('URL')

        if (url) {
            editor?.chain().focus().setImage({ src: url }).run()
        }
    }, [editor])

    return (
        <section className="TipTap">
            {editor ? (
                <>
                    <div className="TipTapMenu">
                        <button
                            onClick={() => editor.chain().focus().toggleBold().run()}
                            className={editor.isActive('bold') ? 'is-active' : ''}
                            aria-label="Bold"
                        >
                            <FormatBold />
                        </button>
                        <button
                            onClick={() => editor.chain().focus().toggleItalic().run()}
                            className={editor.isActive('italic') ? 'is-active' : ''}
                            aria-label="Italic"
                        >
                            <FormatItalic />
                        </button>
                        <button
                            onClick={() => editor.chain().focus().toggleStrike().run()}
                            className={editor.isActive('strike') ? 'is-active' : ''}
                            aria-label="Strikethrough"
                        >
                            <FormatStrikethrough />
                        </button>
                        <button
                            onClick={() => editor.chain().focus().toggleCode().run()}
                            className={editor.isActive('code') ? 'is-active' : ''}
                            aria-label="Inline Code"
                        >
                            <Code />
                        </button>
                        <button aria-label="Link" onClick={setLink} className={editor.isActive('link') ? 'is-active' : ''}>
                            <LinkIcon />
                        </button>
                        {/* <button
                            onClick={() => editor.chain().focus().unsetLink().run()}
                            disabled={!editor.isActive('link')}
                        >
                            unsetLink
                        </button> */}
                        <span className="MenuSpacer"></span>

                        <button
                            onClick={() => editor.chain().focus().setParagraph().run()}
                            className={editor.isActive('paragraph') ? 'is-active' : ''}
                            aria-label="Paragraph"
                        >
                            <SvgIcon sx={{ padding: '3px' }}>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M448 63.1C448 81.67 433.7 96 416 96H384v352c0 17.67-14.33 32-31.1 32S320 465.7 320 448V96h-32v352c0 17.67-14.33 32-31.1 32S224 465.7 224 448v-96H198.9c-83.57 0-158.2-61.11-166.1-144.3C23.66 112.3 98.44 32 191.1 32h224C433.7 32 448 46.33 448 63.1z" /></svg>
                            </SvgIcon>
                        </button>
                        <button
                            onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
                            className={editor.isActive('heading', { level: 1 }) ? 'is-active' : ''}
                            aria-label="Heading 1"
                        >
                            <strong>H1</strong>
                        </button>
                        <button
                            onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
                            className={editor.isActive('heading', { level: 2 }) ? 'is-active' : ''}
                            aria-label="Heading 2"
                        >
                            <strong>H2</strong>
                        </button>
                        <button
                            onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()}
                            className={editor.isActive('heading', { level: 3 }) ? 'is-active' : ''}
                            aria-label="Heading 3"
                        >
                            <strong>H3</strong>
                        </button>
                        <button
                            onClick={() => editor.chain().focus().toggleHeading({ level: 4 }).run()}
                            className={editor.isActive('heading', { level: 4 }) ? 'is-active' : ''}
                            aria-label="Heading 4"
                        >
                            <strong>H4</strong>
                        </button>
                        <button
                            onClick={() => editor.chain().focus().toggleHeading({ level: 5 }).run()}
                            className={editor.isActive('heading', { level: 5 }) ? 'is-active' : ''}
                            aria-label="Heading 5"
                        >
                            <strong>H5</strong>
                        </button>
                        <button
                            onClick={() => editor.chain().focus().toggleHeading({ level: 6 }).run()}
                            className={editor.isActive('heading', { level: 6 }) ? 'is-active' : ''}
                            aria-label="Heading 6"
                        >
                            <strong>H6</strong>
                        </button>
                        <button
                            onClick={() => editor.chain().focus().toggleBulletList().run()}
                            className={editor.isActive('bulletList') ? 'is-active' : ''}
                            aria-label="Bulleted List"
                        >
                            <FormatListBulleted />
                        </button>
                        <button
                            onClick={() => editor.chain().focus().toggleOrderedList().run()}
                            className={editor.isActive('orderedList') ? 'is-active' : ''}
                            aria-label="Numbered List"
                        >
                            <FormatListNumbered />
                        </button>
                        <button
                            onClick={() => editor.chain().focus().toggleCodeBlock().run()}
                            className={editor.isActive('codeBlock') ? 'is-active' : ''}
                            aria-label="Code Block"
                        >
                            <Code sx={{ border: '1px solid black' }} />
                        </button>
                        <button
                            onClick={() => editor.chain().focus().toggleBlockquote().run()}
                            className={editor.isActive('blockquote') ? 'is-active' : ''}
                            aria-label="Blockquote"
                        >
                            <FormatQuote />
                        </button>

                        <span className="MenuSpacer"></span>

                        <button aria-label="Horizontal Rule" onClick={() => editor.chain().focus().setHorizontalRule().run()}>
                            <HorizontalRule />
                        </button>

                        <button aria-label="Image" onClick={addImage}>
                            <PhotoIcon />
                        </button>

                        <button aria-label="Loom Video" onClick={addLoomVideo}>
                            <SvgIcon sx={{ padding: '3px' }}>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M24 10.744h-7.311l6.331-3.656-1.255-2.176-6.333 3.656 3.655-6.332L16.912.98l-3.656 6.33V0h-2.512v7.311L7.088.98 4.912 2.236l3.655 6.33-6.33-3.654L.98 7.088l6.331 3.656H0v2.513h7.31L.98 16.913l1.255 2.176 6.333-3.656-3.656 6.332 2.175 1.256 3.657-6.331V24h2.512v-7.467l3.723 6.448 2.04-1.178-3.723-6.45 6.467 3.735 1.257-2.176-6.331-3.656h7.31v-2.512H24Zm-12 4.672a3.415 3.415 0 0 1-2.959-5.125A3.415 3.415 0 0 1 15.415 12 3.416 3.416 0 0 1 12 15.416Z" fill="#000" fillRule="nonzero" /></svg>
                            </SvgIcon>
                        </button>

                        <button aria-label="Hard Return" onClick={() => editor.chain().focus().setHardBreak().run()}>
                            <KeyboardReturn />
                        </button>

                        <span className="MenuSpacer"></span>

                        <button aria-label="Undo" onClick={() => editor.chain().focus().undo().run()}>
                            <Undo />
                        </button>

                        <button aria-label="Redo" onClick={() => editor.chain().focus().redo().run()}>
                            <Redo />
                        </button>

                        <button onClick={() => editor.chain().focus().unsetAllMarks().clearNodes().run()}>
                            <FormatClear />
                        </button>
                    </div>

                    {/* {console.log(test('<div style="position: relative; padding-bottom: 56.60377358490566%; height: 0;"><iframe src="https://www.loom.com/embed/afbd24770d5548e59013a5e3e4b11cca" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;"></iframe></div>'))} */}

                    <EditorContent editor={editor} />

                    {/* <textarea value={content} onChange={(e) => onChange(e.target.value)} /> */}

                    {/* <button onClick={() => onChange(editor.getHTML())}>Save Content</button> */}
                </>
            ) : null}

        </section>
    )
}

export default Tiptap;