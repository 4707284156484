// import { useContext } from 'react';
// import { ThemeContext } from '../theme/ThemeContext';
import './Layout.scss';
import { useFirebaseAuth } from "../services/firebase";
import { Link } from 'react-router-dom';

function Layout(props: any) {
    const { doSignOut, user } = useFirebaseAuth();

    return (
        <section className="Layout">
            <nav>
                <Link className="logo-link" to="/">
                    <img src="/logo.png" className="App-logo" alt="logo" />
                    {/* [LOGO] */}
                </Link>
                {user?.id?.length ? <div className="btn btn-primary btn-sm" onClick={doSignOut}>Log Out</div> : null}
            </nav>

            <main>{props.children}</main>

            <footer>
                <div className="font-10">v{process.env.REACT_APP_VERSION}</div>
                <div>&copy; 2022 Tim Noetzel</div>
                <div></div>
            </footer>
        </section>
    );
}

export default Layout;