import { Playbook, UserPlaybookStatus } from "../../shared/types";

function PlaybookCard(props: { playbook: Playbook, status?: UserPlaybookStatus }) {
    const { playbook, status } = props;

    const totalTaskCount = Object.keys(playbook.tasks).length;
    const completedTaskCount = Object.values(status?.tasks || {}).filter((taskStatus) => taskStatus === true).length || 0;
    let statusClass = '';
    if (completedTaskCount >= totalTaskCount) {
        statusClass = 'playbook-complete';
    } else if (completedTaskCount > 0) {
        statusClass = 'playbook-current';
    }

    return (
        <div className={"card playbook-card mb-100 " + statusClass}>
            <div className="playbook-title">{playbook.name}</div>
            <div>{completedTaskCount} of {totalTaskCount} completed.</div>
            <hr className="mt-75 mb-75" />
            <div className="playbook-goal">Goal</div>
            <div>{playbook.description}</div>
        </div>
    );
}

export default PlaybookCard;