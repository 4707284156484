import { Library } from '../../shared/types';

// import slugify from 'slugify';

// export function slugifyString(str: string) {
//     // https://stackoverflow.com/questions/3210393/how-do-i-remove-all-non-alphanumeric-characters-from-a-string-except-dash?rq=1
//     return slugify(str, { lower: true, remove: /[^a-zA-Z0-9 -]/g });
// }

// import slugify from '@sindresorhus/slugify';

// export function slugifyString(str: string) {
//     return slugify(str);
// }

// { order?: number, [index: string | number]: unknown }
// Library<Task | Playbook | UserPlaybookStatus>

export function createListFromOrderedItems<T extends { order?: number }>(items: Library<T>) {
    return Object.entries(items).map((val) => ({ id: val[0], order: val[1].order, ...val[1] })).sort((a, b) => (a.order || 0) < (b.order || 0) ? -1 : 1);
}

export function sortItemList(itemList: { order?: number }[]) {
    return itemList.sort((a, b) => (a.order || 0) < (b.order || 0) ? -1 : 1);
}

// const gpsUtil = {
//     createListFromOrderedItems: createListFromOrderedItems
//     // slugifyString: slugifyString
// }
// export default gpsUtil;